<template>
<div class="top-logo">
  <router-link
      class="logo"
      :to="$layout.specialPages.home.href"
  >
    <img :src="logo"
         width="407" height="184"
         :title="alt"
         :alt="alt"
         :style="styleLogo"
    >
  </router-link>
</div>
</template>

<script>
export default {
  name: "top-logo",
  computed: {
    logo() {
      let r=`${this.$layout.publicPath}logo-${this.$layout.site}-v3.svg`;
      if(this.stud){
        r=`${this.$layout.publicPath}logo-studios.svg`;
      }
      return r;
    },
    alt(){
      if(this.stud){
        return "Les Studios de la Montjoie by VOLT & PICSEYES";
      }
      return "Picseyes logo";
    },
    styleLogo(){
      if(this.stud){
        return "padding-top:5px;padding-left:5px;"
      }
      return ""
    },
    stud(){
      if(this.$layout.currentPage.pageUrl){
        return this.$layout.currentPage.pageUrl.slug==="studios" && this.$layout.site==="prod";
      }
      return false;

    }
  }
}
</script>

<style lang="less">
.top-logo{

  display: flex;
  .logo{
    text-align: left;
    flex-grow: 0;
    img{
      height: 100%;
      display: block;
      width: auto;
    }
  }
}
</style>